document.addEventListener('turbolinks:load', () => {
  const charts = document.querySelectorAll('.js-chartkick-chart')
  if (charts.length) {
    Promise.all([import('chartkick'), import('chart.js')]).then(([Chartkick]) => {
      // NOTE: The following will be part of Chartkick 4.0
      // https://github.com/ankane/chartkick/issues/536#issuecomment-720229441
      window.dispatchEvent(new Event('chartkick:load'))

      Chartkick.destroyAll = function () {
        for (var id in Chartkick.charts) {
          Chartkick.charts[id].destroy()
          delete Chartkick.charts[id]
        }
      }

      document.addEventListener('turbolinks:before-render', () => {
        Chartkick.destroyAll()
      })
    })
  }
})