import $ from "jquery"

function refreshToasts() {
  $('.toast').toast({
    delay: 5000
  }).toast('show')
}

function refreshTooltips() {
  $("*[data-toggle=tooltip]").tooltip()
}

function prepareCollapseForCaching() {
  $(".collapse.show").removeClass("show")
}

$(document).on("turbolinks:load", function(){
  refreshTooltips()
  refreshToasts()
})

$(document).on("turbolinks:before-cache", function(){
  prepareCollapseForCaching()
})
